<template>
  <b-form-group>
    <template #label>
      <div>
        {{ $t(`promotionAg.${label}`) }}
      </div>
    </template>

    <v-select
      :id="label"
      v-model="inputData"
      multiple
      style="font-size: 1rem;"
      label="agencyName"
      class="v-select-wrap"
      :close-on-select="false"
      :reduce="val => ({agencyName: val.agencyName, agencyCode: val.agencyCode})"
      :filterable="false"
      :loading="loading"
      :placeholder="$t(`${placeholder}`)"
      :options="agenciesManagerList"
      :disabled="disabled"
      @open="openAgenciesManager"
      @search="searchAgenciesManager"
      @search:blur="handleUpdateSelect"
    >
      <template #option="data">
        <div class="d-flex-center justify-content-between">
          <span class="d-block text-nowrap">
            {{ data.agencyName }}
          </span>
          <span class="d-block text-nowrap">
            {{ data.agencyCode }}
          </span>
        </div>
      </template>
      <template #spinner="data">
        <div
          v-if="data.loading"
          style="border-left-color: rgba(88, 151, 251, 0.71)"
          class="vs__spinner"
        />
      </template>
      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { cloneDeep } from 'lodash'

import usePromotionAgHandle from '@promotionAg/usePromotionAgHandle'

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    value: {
      type: [Array, null],
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
    } = usePromotionAgHandle()
    const inputData = ref([])

    watch(() => props.value, val => {
      inputData.value = cloneDeep(val)
    }, { deep: true })

    function handleUpdateSelect() {
      emit('updateValue', inputData.value.map(it => it.agencyCode))
    }

    return {
      inputData,
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      handleUpdateSelect,
      openAgenciesManager,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
  .vs__dropdown-menu {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  }
}
</style>
